<template>
    <div id="messagePage">
        <table-list
            :ButtonWidth="120"
            :tableData="tableData"
            :colrs="colrs"
            :total="total"
            :pagesize="pagesize"
            :pageIndex="pageIndex"
            :selection="$route.query.recovery == 0"
            @currentChange="currentChange"
            @handleSizeChange="handleSizeChange"
        >
            <template #TableItem="scope">
                <span v-if="scope.colr.prop == 'read'">
                    <span v-if="scope.row[scope.colr.prop]" style="color: #67c23a">已读</span>
                    <span v-else style="color: #e44c4c">未读</span>
                </span>

                <span v-else-if="scope.colr.prop == 'remark'">
                    第{{ scope.row["latestRepaymentRecord"] }}/{{ scope.row["stageNumber"] }}期{{
                        scope.row[scope.colr.prop]
                    }}
                </span>
            </template>
            <template #Button="scope">
                <el-button size="mini" type="text" @click="check(scope.row)">查看</el-button>
            </template>
        </table-list>
    </div>
</template>
<script>
import TableList from "../../components/comm/TableList.vue";
import paymentOrderApi from "../../api/paymentOrderApi";
export default {
    name: "messagePage",
    components: { TableList },

    data() {
        return {
            checkId: null,
            checkForm: {},
            showbigImg: false,
            bigImgSrc: "",
            total: 0,
            pagesize: 20,
            pageIndex: 1,
            tableData: [{}],
            colrs: [
                { label: "订单号", prop: "id" },
                { label: "客户姓名", prop: "customerName" },
                { label: "通知详情", prop: "info" },
                { label: "状态", prop: "read" }
            ],
            newsNumber: 0,

            pageSize: 20,
            pageNumber: 0,
            total: 0,

            title: "消息列表",
            id: this.$route.query.id
        };
    },

    mounted() {
        document.title = "代扣系统-消息列表";
        this.getStages();
        this.$bus.$on("reload-data", this.getStages);
    },
    methods: {
        getStages() {
            this.$router.push({ path: this.$route.path, query: {} });
            paymentOrderApi
                .listWithPaymentRecord({
                    pageSize: this.pageSize,
                    pageNumber: this.pageNumber
                })
                .then(res => {
                    if (res.success) {
                        this.tableData = res.data.records
                            .filter(item => item.repaymentRecords)
                            .map(item => {
                                if (item.isStage) {
                                    if (item.latestRepaymentRecord) {
                                        item.info = `第${item.latestRepaymentRecord.stage}/${item.stageNumber}期${item.latestRepaymentRecord.repaymentStatusDesc}`;
                                        item.read = item.latestRepaymentRecord.isRead;
                                    }
                                }else{
                                    if (item.latestRepaymentRecord) {
                                        item.info = `单笔扣款${item.latestRepaymentRecord.repaymentStatusDesc}`;
                                        item.read = item.latestRepaymentRecord.isRead;
                                    }
                                }
                                
                                return item;
                            });
                    }
                });
        },
        check(row) {
            console.log(row);
           
            if (row.isStage) {
                this.$router.push({
                    name: "messageDetail",
                    query: { id: row.id, name: row.customerName, stageNumber: row.stageNumber, isStage: 1}
                });
            }else{
                this.$router.push({
                    name: "messageDetail",
                    query: { id: row.id, name: row.customerName, isStage: 0}
                });
            }
            
        },

        currentChange(pageIndex) {
            this.pageIndex = pageIndex - 1;
        },
        handleSizeChange(pageSize) {
            this.pageIndex = 1;
            this.pagesize = pageSize;
        }
    }
};
</script>
<style>
</style>
<style lang="scss" scoped>
#messagePage {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    height: 89vh;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin: 0 auto;
    }
    .headBox {
        text-align: center;
        margin-bottom: 16px;
        position: relative;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .backBtn {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .right {
        display: flex;
        justify-content: right;
        align-items: center;
    }
    .header_tit {
        font-weight: 700;
        font-size: 16px;
        padding-left: 10px;
    }

    .box-card {
        margin-bottom: 20px;
        width: 100%;
        /deep/.el-card__header {
            background: #f4f4f5;
        }
        .clearfix {
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .readGreen {
        color: #67c23a;
    }
    .readRen {
        color: #f56c6c;
    }

    /deep/.el-table .cell {
        line-height: normal !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    /deep/ .el-button--text {
        height: 16px;
        padding: 0;
    }
}
</style>

